<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12"  class="form-for-textarea">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <b-row>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Sales Center Name"  vid="sales_center_name" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="sales_center_name"
                                    slot-scope="{ valid, errors }"
                                    style="font-size:12px"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.sales_center.sales_center_name')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="sales_center_name"
                                        v-model="sales_center.sales_center_name"
                                        :placeholder="$t('fertilizerConfig.sales_center.sales_center_name')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Sales Center Name Bn"  vid="sales_center_name_bn" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="sales_center_name_bn"
                                    slot-scope="{ valid, errors }"
                                    style="font-size:12px"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.sales_center.sales_center_name_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="sales_center_name_bn"
                                        v-model="sales_center.sales_center_name_bn"
                                        :placeholder="$t('fertilizerConfig.sales_center.sales_center_name_bn')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="division"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="sales_center.division_id"
                                :options="divisionList"
                                id="division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                @input="removeDivUp()"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="district"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="sales_center.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                @input="removeUp()"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="upazila"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="sales_center.upazilla_id"
                                :options="upazilaList"
                                id="upazila_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <div class="col-12"></div>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Location"  vid="location" rules="required|max:255">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="location"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.sales_center.location')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                        id="location"
                                        v-model="sales_center.location"
                                        :placeholder="$t('fertilizerConfig.sales_center.location')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Location Bn"  vid="location_bn" rules="required|max:255">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="location_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.sales_center.location_bn')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-textarea
                                        id="location_bn"
                                        v-model="sales_center.location_bn"
                                        :placeholder="$t('fertilizerConfig.sales_center.location')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="description"  vid="description" rules="max:255">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="description"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.sales_center.description')}}
                                    </template>
                                    <b-form-textarea
                                        id="description"
                                        v-model="sales_center.description"
                                        :placeholder="$t('fertilizerConfig.sales_center.description')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Description Bn"  vid="description_bn"  rules="max:255">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="description_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.sales_center.description_bn')}}
                                    </template>
                                    <b-form-textarea
                                        id="description_bn"
                                        v-model="sales_center.description_bn"
                                        :placeholder="$t('fertilizerConfig.sales_center.description_bn')"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { salesCenterStore, salesCenterUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getsales_centerData()
      this.sales_center = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      sales_center: {
        id: '',
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        location: null,
        location_bn: null
      },
      districtList: [],
      upazilaList: [],
      attachmentDemo: ''
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'sales_center.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'sales_center.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  methods: {
    removeDivUp () {
      if (this.id) {
        this.sales_center.upazilla_id = null
        this.sales_center.district_id = null
      }
    },
    removeUp () {
      if (this.id) {
        this.sales_center.upazilla_id = null
      }
    },
    getIdSelect (upzilaId) {
      this.sales_center.upazilla_id = upzilaId
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    onFileChange (e) {
      this.attachmentDemo = e.target.files[0]
    },
    getsales_centerData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      if (this.sales_center.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${salesCenterUpdate}/${this.id}`, this.sales_center)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, salesCenterStore, this.sales_center)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
    }
  }
}
</script>
